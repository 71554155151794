import React, { Component } from 'react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { connect } from 'react-redux';
import { getPrivacyPolicy } from '../../store/actions';
import Loading from '../../components/spinner';
import './index.css';
import { Title } from '../../components/common';

class Privacy extends Component {
  componentDidMount() {
    this.props.dispatch(getPrivacyPolicy());
  }

  render() {
    const { __, privacyPolicy } = this.props;
    return (
      <Loading>
        <Layout showHamburger={false}  hideSecondToolbar={true}  color="transparent"  headerWithTitle={true} title={__('Privacy Policy')} headerTitle={__('Privacy Policy')}>
          <div className='page-content'>
            <Title>{__('Privacy Policy')}</Title>
            <div dangerouslySetInnerHTML={{ __html: privacyPolicy }}></div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    privacyPolicy: state.common.privacyPolicy || null,
  };
};

export default connect(stateToProps)(withTranslation(Privacy));
