import React from 'react';
import { withRouter } from 'react-router';
import { IonHeader, IonToolbar, IonMenuButton, IonButtons, IonButton, IonBadge, IonIcon } from '@ionic/react';
import { basket, search } from 'ionicons/icons';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import Icon from '../icon';
import BackButton from '../backButton';
import { checkBackgroundColor, forwardTo, getDefaultRoute, getRouteClassName, isWebConfig } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { getConfig } from '../../appConfig';
import './index.css';
import { Title } from '../common';
import scan from '../../assets/images/icons/scan.svg';

const StaticHeader = ({ history, onSearchHandler, backHandler, clientProfile, navConfig, showHamburger, headerIconColors, clientStyles, hasSearchIcon, searchActive, headerWithTitle, title, disableHeaderLogo }) => {
	// const logo_image = clientProfile.logo_image
	//style={{ '--background': isWebConfig() ? '' : `#FFF url(${logo_image}) no-repeat 50% 50%/auto 90%` }
	const currentPath = history.location.pathname;
	const authPages = getConfig().general.authRoutes.indexOf(currentPath) !== -1;
	const hideStaticHeader = getConfig().theme.routesWithoutStaticHeader.indexOf(currentPath) !== -1;
	const pagesWithoutBackButton = getConfig().general.routesWithoutBackButton.indexOf(currentPath) !== -1;
	const headerImageUrl = getConfig().headerImage ? getConfig().headerImage.headerImageUrl : null;
	const headerImageSize = getConfig().headerImage ? getConfig().headerImage.headerImageSize : null;
	const routeClassName = getRouteClassName(currentPath, navConfig);
	let defaultIconsColor = '';
	if (clientStyles?.theme !== 'custom') {
		defaultIconsColor = clientStyles?.theme == 'dark' ? 'white' : 'dark';
	} else {
		defaultIconsColor = clientStyles?.colors?.['--okx-background-color'] ? checkBackgroundColor(clientStyles?.colors['--okx-background-color']) : 'dark';
	}

	let logoMain;
	if (headerImageUrl) {
		logoMain = headerImageUrl;
	} else if (headerIconColors) {
		if (headerIconColors === 'white') {
			logoMain = clientProfile.logo_image_light || clientProfile.logo_image || '';
		} else if (headerIconColors === 'dark') {
			logoMain = clientProfile.logo_image || '';
		}
	} else {
		if (defaultIconsColor === 'white') {
			logoMain = clientProfile.logo_image_light || clientProfile.logo_image || '';
		} else if (defaultIconsColor === 'dark') {
			logoMain = clientProfile.logo_image || '';
		}
	}
	return (
		<>
			{hideStaticHeader ? null : getConfig().theme.showHeaderOnAuthRoutes || !authPages ? (
				<IonHeader className={`static ${routeClassName} ${getConfig().general.dashboardWithTiles ? 'colored-header' : 'white-header'}`}>
					<IonToolbar className={`${headerWithTitle ? 'no-logo' : ''} primary-toolbar`}>
						<IonButtons slot="start">
							{!authPages && (pagesWithoutBackButton || showHamburger) ? (
								<IonMenuButton color={headerIconColors ? headerIconColors : defaultIconsColor} />
							) : (
								<>
									<BackButton backHandler={backHandler} iconColor={headerIconColors ? headerIconColors : defaultIconsColor} />
								</>
							)}
						</IonButtons>
						{!headerWithTitle ? (
							<>
								{
									headerImageUrl ? (
										<IonButtons className="image-button-resizable-wrapper" color="primary" >
											<img src={logoMain}
												style={{
													maxWidth: headerImageSize ? headerImageSize : 'auto',
													height: 'auto',
													display: isWebConfig() ? 'none' : 'block'
												}}
												className="image-button-resizable"
												color="primary-shade"
												onClick={() => disableHeaderLogo?null: forwardTo(getConfig().general.defaultRoute ? getConfig().general.defaultRoute : getDefaultRoute(navConfig).path)}
											/>
										</IonButtons>
									) : (
										<IonButtons className="image-button-wrapper" color="primary">
											<IonButton
												style={{
													backgroundImage: !isWebConfig() && !headerWithTitle ? `url(${logoMain})` : '',
													backgroundSize: 'cover',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',

												}}
												className="image-button"
												color="primary-shade"
												onClick={() => disableHeaderLogo?null:forwardTo(getConfig().general.defaultRoute ? getConfig().general.defaultRoute : getDefaultRoute(navConfig).path)}
											/>
										</IonButtons>
									)
								}
							</>
						) : (
							<IonButton color="transparent" className="header-title" onClick={() => forwardTo('/dashboard')}>
								<Title className={`${defaultIconsColor}-header-title bold`}>{title}</Title>
							</IonButton>
						)}
						<IonButtons slot="end" className={`${searchActive ? 'button-active' : ''}`}>
							{hasSearchIcon ? (
								<>
									<IonButton className="header-search" color={headerIconColors ? headerIconColors : defaultIconsColor} button clear onClick={() => onSearchHandler()}>
										<IonIcon color="primary" slot="icon-only" icon={search} />
									</IonButton>
								</>
							) : (
								<>
									{Basket.itemsCount() > 0 && !Basket.is_gift ? (
										<IonButton color={headerIconColors ? headerIconColors : defaultIconsColor} className="basket-button" onClick={() => forwardTo('/order-summary')} size={24}>
											<div>
												<div className="basket-icon-wrapper">
													<IonBadge slot="end" color="primary" className='badge'>
														{Basket.itemsCountAll()}
													</IonBadge>
													<Icon icon={basket} classname="icon" color={headerIconColors ? headerIconColors : defaultIconsColor} />
												</div>
												<div>{/* <SmallText color="gray">
													{ Basket._getTotal() }
												</SmallText> */}</div>
											</div>
										</IonButton>
									) : !authPages && !isWebConfig() ? (
										getConfig().flags.hasLoyalty ? (
											<IonButton className="header-scan-button" color={headerIconColors ? headerIconColors : defaultIconsColor} button clear onClick={() => forwardTo('/loyalty', { tab: 'scan' })}>
											<IonIcon slot="icon-only" icon={scan} />
										</IonButton>
										) : null
									) : null}
								</>
							)}
						</IonButtons>
					</IonToolbar>
				</IonHeader>
			) : null}
		</>
	);
};

const stateToProps = (store) => {
	const { orders } = store;
	const { clientProfile, navConfig, clientStyles } = store.common;
	return {
		basketUpdated: orders.basketUpdated,
		clientProfile,
		navConfig,
		clientStyles,
	};
};

export default connect(stateToProps)(withRouter(withTranslation(StaticHeader)));
