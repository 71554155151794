import { isWebConfig } from './lib/utils';
import Dashboard from './screens/dashboard';
import Loyalty from './screens/loyalty';
import Account from './screens/account';
import Feedback from './screens/feedback';
import ReferAFriend from './screens/referAFriend';
import History from './screens/history';
import HistoryDetails from './screens/historyDetails';
import Locations from './screens/locations';
import Social from './screens/social';
import Login from './screens/login';
import Register from './screens/register';
import ResetPassword from './screens/resetPassword';
import Terms from './screens/terms';
import Privacy from './screens/privacy';
import Faq from './screens/faq';
import ItemDetails from './screens/itemDetails';
import DeliveryOptions from './screens/delivery-options';
import Delivery from './screens/delivery';
import DeliveryAddressCheck from './screens/delivery-address-check';
import DeliveryAddressAdd from './screens/delivery-address-add';
import DeliveryTime from './screens/delivery-time';
import ClickAndCollect from './screens/clickAndCollect';
import Cards from './screens/cards';
import CardAdd from './screens/cardAdd';
import OrderSummary from './screens/orderSummary';
import ApplyPoints from './screens/applyPoints';
import Checkout from './screens/checkout';
import OrderCompleted from './screens/orderCompleted';
import ContactDetails from './screens/contactDetails';
import ApplyVouchers from './screens/applyVouchers';

// import AllergensInfo from './screens/allergensInfo'
import OrderToTable from './screens/orderToTable';
import { getConfig } from './appConfig';
import { getSingleDeliveryOption } from './lib/utils';
import { ServiceCharge } from './screens/service-charge';
import ApplyLoyalty from './screens/applyLoyalty';
import MyMembership from './screens/myMembership';
import MembershipCompleted from './screens/membershipCompleted';
import CheckoutMembership from './screens/checkoutMembership';
import CardAddMembersip from './screens/cardAddMembership';
import ChooseSubscriptionMenu from './screens/chooseSubscriptionMenu';
import ChooseSubscription from './screens/chooseSubscription';
import TermsAndConditions from './screens/terms-subscription-package';
import api from './lib/api';
import CreateAccount from './screens/createAccount';
// import PayTableBill from './screens/payTableBill';
// import PayTableOverview from './screens/payTableOverview';
// import SplitBill from './screens/splitBill';
// import SplitBillByAmount from './screens/splitBillByAmount';
// import SplitBillByItems from './screens/splitBillByItems';
// import BillCompleted from './screens/billCompleted';
import PickUpAtCounter from './screens/pickUpAtCounter';
import BrowseMenu from './screens/browseMenu';
import GiftVouchers from './screens/giftVouchers';
import SentGiftVouchers from './screens/sentGiftVouchers';
import GiftVoucherOverview from './screens/giftVoucherOverview';
import GiftBalance from './screens/giftBalance';
const Order = require('./screens/order' + (isWebConfig() ? 'Web' : '')).default;

import home from './assets/images/home.svg';
import loyalty from './assets/images/loyalty.svg';
import myAccount from './assets/images/account.svg';
import feedback from './assets/images/feedback.svg';
import referAFriend from './assets/images/refer.svg';
import history from './assets/images/history.svg';
import locations from './assets/images/locations.svg';
import social from './assets/images/social-media.svg';
import login from './assets/images/login.svg';
import logout from './assets/images/logout.svg';
import settings from './assets/images/settings.svg';
import startNewOrder from './assets/images/order.svg';
import menu from './assets/images/menu.svg';
import terms from './assets/images/terms.svg';
import privacy from './assets/images/privacy.svg';
import qm from './assets/images/qm.svg';
import gift from './assets/images/gift.svg';
import ScheduledDelivery from './screens/scheduled-delivery';
import ScheduledDeliveryTime from './screens/scheduled-delivery-time';
import nativeOrderStatus from './components/nativeOrderStatus';
import award from './assets/images/icons/award.svg';
import helpCircle from './assets/images/icons/help_circle.svg';
import clockSearch from './assets/images/icons/clock_search.svg';
import calendarClock from './assets/images/icons/calendar_clock.svg';

let navConfig;

export const initRouter = async () => {
  const config = await api.getFrontEndAppConfig();

  const singleDeliveryOption = await getSingleDeliveryOption();
  const {
    hasOrdering,
    hasBillPay,
    hasGiftVouchers,
    hasMembership,
    hasGuestCheckout,
    hasReferAFriend,
    hasLoyalty,
    defaultRoute,
    hasRewards
  } = config.front_end_app_config.flags;
  const bookATableUrl = config?.front_end_app_config?.general?.bookATableUrl;
  
  navConfig = {
    routes: [
      isWebConfig()
        ? []
        : {
            label: 'Home',
            path: '/dashboard',
            component: Dashboard,
            icon: home,
            exact: true,
            default: true,
          },
      ...(hasOrdering
        ? [
            {
              label: 'Menu',
              path: '/order',
              component: Order,
              icon: menu,
              exact: true,
              default: isWebConfig() && hasOrdering,
              group: 'button',
            },
          ]
        : []),
      // ...(hasBillPay
      // 	? [
      // 			{
      // 				icon: menu,
      // 				label: 'Pay Your Bill',
      // 				path: '/table-bill-pay',
      // 				component: PayTableBill,
      // 				protected: false,
      // 			},
      // 	  ]
      // 	: []),

      {
        label: 'Apply Loyalty',
        path: '/apply-loyalty',
        component: ApplyLoyalty,
        icon: menu,
        protected: true,
        notInDrawer: true,
      },
      ...(hasMembership
        ? [
            {
              label: 'Membership',
              path: '/get-membership',
              component: ChooseSubscriptionMenu,
              icon: menu,
              protected: true,
              group: 2,
              default: isWebConfig() && defaultRoute === 'membership',
            },
            {
              label: 'Membership',
              path: '/membership',
              component: ChooseSubscription,
              icon: home,
              notInDrawer: true,
              protected: true,
            },
          ]
        : []),
      {
        label: 'TermsAndConditions',
        path: '/membership-terms',
        component: TermsAndConditions,
        notInDrawer: true,
        protected: true,
      },
      {
        label: 'Checkout Membership',
        path: '/checkout-membership',
        component: CheckoutMembership,
        notInDrawer: true,
        protected: true,
      },
      {
        label: 'MyMembership',
        path: '/my-membership',
        component: MyMembership,
        notInDrawer: true,
        protected: true,
      },
      {
        label: 'Membership Completed',
        path: '/membership-completed',
        component: MembershipCompleted,
        notInDrawer: true,
        protected: false,
      },
      {
        label: 'Add card Membership',
        path: '/add-card-membership',
        component: CardAddMembersip,
        notInDrawer: true,
        protected: true,
      },
      ...(hasOrdering
      	? [
      			{
      				label: singleDeliveryOption ? singleDeliveryOption.label : '',
      				path: '/click-and-collect',
      				component: ClickAndCollect,
      				icon: settings,
      				protected: false,
      				notInDrawer: !singleDeliveryOption,
      			},
      			{
      				label: singleDeliveryOption ? singleDeliveryOption.label : '',
      				path: '/click-and-collect-scheduled',
      				component: ClickAndCollect,
      				icon: settings,
      				protected: false,
      				notInDrawer: !singleDeliveryOption,
      				scheduled: true,
      			},
      	  ]
      	: []),
      ...(hasOrdering
      	? [
      			{
      				label: 'Start New Order',
      				path: '/delivery-options',
      				component: DeliveryOptions,
      				icon: startNewOrder,
      				protected: false,
      				notInDrawer: !!singleDeliveryOption,
      			},
      	  ]
      	: []),
      ...(hasLoyalty
        ? [
            {
              label: 'Loyalty',
              path: '/loyalty',
              component: Loyalty,
              icon: award,
              protected: true,
              default: isWebConfig() && defaultRoute === 'loyalty',
              state: { tab: 'points' },
              group: 1,
            }
          ]
        : []),
      ...(hasRewards
        ? [
            {
              label: 'Rewards',
              path: '/loyalty',
              component: Loyalty,
              icon: social,
              default: false,
              protected: true,
              group: 1,
            }
          ]
        : []),
      ...(hasReferAFriend
        ? [
            {
              label: 'Refer A Friend',
              path: '/refer-a-friend',
              component: ReferAFriend,
              icon: referAFriend,
              protected: true,
              group: 1,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: 'Checkout',
              path: '/checkout',
              component: Checkout,
              notInDrawer: true,
              protected: !hasGuestCheckout,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: 'Apply Points',
              path: '/apply-points',
              component: ApplyPoints,
              notInDrawer: true,
              protected: true,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: 'Service Charge',
              path: '/service-charge',
              component: ServiceCharge,
              notInDrawer: true,
              protected: true,
            },
          ]
        : []),
      {
        label: 'My Account',
        path: '/account',
        component: Account,
        icon: myAccount,
        protected: true,
        group: 1,
      },
      ...(hasGiftVouchers
				? [
						{
							label: 'Send a voucher',
							path: '/gift-vouchers',
							component: GiftVouchers,
							icon: gift,
							protected: false,
							group: 4,
							default: isWebConfig() && defaultRoute === 'gift-vouchers',
						},
						{
							label: 'Check balance',
							path: '/voucher-balance',
							component: GiftBalance,
							protected: false,
							icon: gift,
							group: 4,
							default: isWebConfig() && defaultRoute === 'gift-vouchers',
						},
						{
							label: 'Sent vouchers',
							path: '/sent-vouchers',
							component: SentGiftVouchers,
							icon: gift,
							protected: true,
							group: 4,
							default: isWebConfig() && defaultRoute === 'gift-vouchers',
						},
						{
							path: '/gift-voucher',
							component: GiftVoucherOverview,
							icon: gift,
							protected: false,
							notInDrawer: true,
							default: isWebConfig() && defaultRoute === 'gift-vouchers',
						},
				  ]
				: []),
      {
        label: 'History',
        path: '/history',
        component: History,
        icon: history,
        protected: true,
        group: 1,
      },
      {
        label: 'App support',
        path: '/feedback',
        component: Feedback,
        icon: helpCircle,
        protected: true,
        group: 1,
      },
      ...(bookATableUrl
        ? [
          {
            label: 'Book a Table',
            path: bookATableUrl,
            component: Locations,
            icon: calendarClock,
            protected: false,
            group: 3,
            isLink: true,
          }
          ]
        : []),
      {
        label: 'Locations',
        path: '/locations',
        component: Locations,
        icon: locations,
        protected: false,
        group: 3,
      },
      {
        label: "What's on",
        path: '/social',
        component: Social,
        icon: clockSearch,
        protected: false,
        group: 3,
      },
      {
        label: 'Contact Details',
        path: '/contact-details',
        component: ContactDetails,
        protected: !hasGuestCheckout,
        notInDrawer: true,
      },
      {
        label: 'Apply Vouchers',
        path: '/apply-vouchers',
        component: ApplyVouchers,
        notInDrawer: true,
        protected: true,
      },
      {
        label: 'FAQ',
        path: '/faq',
        component: Faq,
        icon: qm,
        protected: false,
        notInDrawer: false,
      },
    ],
    authRoutes: [
      { label: 'Login', path: '/login', component: Login, icon: logout, fn: 'login' },
      { label: 'Logout', path: '/logout', icon: login, fn: 'logout' },
    ],
    additionalRoutes: [
      { label: 'T&Cs', path: '/terms', component: Terms, icon: terms },
      { label: 'Privacy Policy', path: '/privacy', component: Privacy, icon: privacy },
    ],
    notInMenuRoutes: [
      { path: '/register', component: Register },
      { path: '/create-account', component: CreateAccount },
      { path: '/reset-password', component: ResetPassword },
      ...(hasOrdering ? [{ path: '/order', component: Order }] : []),
      ...(hasOrdering ? [{ path: '/item-details', component: ItemDetails }] : []),
      ...(hasOrdering || hasGiftVouchers || hasMembership
        ? [{ path: '/cards', component: Cards }]
        : []),
      ...(hasOrdering || hasGiftVouchers || hasMembership
        ? [{ path: '/card-add', component: CardAdd }]
        : []),
      ...(hasOrdering ? [{ path: '/history-details', component: HistoryDetails }] : []),
      ...(hasOrdering ? [{ path: '/order-summary', component: OrderSummary }] : []),
      ...(hasOrdering ? [{ path: '/order-completed', component: OrderCompleted }] : []),
      ...(hasOrdering ? [{ path: '/order-status', component: nativeOrderStatus }] : []),
      ...(hasOrdering ? [{ path: '/order-to-table', component: OrderToTable }] : []),
      ...(hasOrdering ? [{ path: '/delivery', component: Delivery }] : []),
      ...(hasOrdering ? [{ path: '/scheduled-delivery', component: ScheduledDelivery }] : []),

      ...(hasOrdering
        ? [{ path: '/delivery-address-check', component: DeliveryAddressCheck }]
        : []),
      ...(hasOrdering ? [{ path: '/delivery-address-add', component: DeliveryAddressAdd }] : []),
      ...(hasOrdering ? [{ path: '/delivery-time', component: DeliveryTime }] : []),
      ...(hasOrdering ? [{ path: '/scheduled-delivery-time', component: ScheduledDeliveryTime }] : []),

      ...(hasOrdering ? [{ path: '/pick-up-at-counter', component: PickUpAtCounter, protected: false }] : []),
      ...(hasOrdering ? [{ path: '/browse-menu', component: BrowseMenu }] : []),

      // ...(hasOrdering ? [{ path: '/table-overview', component: PayTableOverview, protected: false }] : []),
      // ...(hasOrdering ? [{ path: '/split-bill', component: SplitBill, protected: false }] : []),
      // ...(hasOrdering ? [{ path: '/split-bill-by-amount', component: SplitBillByAmount, protected: false }] : []),
      // ...(hasOrdering ? [{ path: '/split-bill-by-items', component: SplitBillByItems, protected: false }] : []),
      // ...(hasOrdering ? [{ path: '/bill-completed', component: BillCompleted, protected: false }] : []),
    ],
  };

  return navConfig;
};

export default navConfig;
