import React from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonTextarea,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRadioGroup,
  IonRadio,
  IonAlert,
} from '@ionic/react';
import { withRouter } from 'react-router';
import { starOutline, star } from 'ionicons/icons';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { getRestaurants, logout, sendFeedback, setCommonModal } from '../../store/actions';
import {
  FieldError,
  Title,
  StrongText,
  SmallText,
  Spacer,
  NormalText,
} from '../../components/common';
import { validateForm, isDefined, isWebConfig, goBack, forwardTo } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Mobiscroll from '../../components/mobiscroll';
import './index.css';

const { SelectOption } = Mobiscroll;

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.resetState(),
    };
    this.formConfig = {
      food: { type: 'integer', required: true, invalidValue: 0 },
      service: { type: 'integer', required: true, invalidValue: 0 },
      selectedRestaurant: { type: 'select', required: true, invalidValue: null },
    };
  }

  resetState = () => ({
    food: 0,
    service: 0,
    customerService: false,
    techSupport: true,
    commentService: '',
    commentTech: '',
    radioGroup: 'customerService',
    selectedRestaurant: null,
    formErrors: {},
    orderTypeFeedback: '',
  });

  createStarSet(size, name) {
    return <>{[...Array(size).keys()].map((i) => this.createStar(name, i + 1))}</>;
  }

  createStar = (name, value) => {
    if (this.state[name] >= value) {
      return (
        <IonIcon
          key={value}
          icon={star}
          className="star"
          onClick={() => this.setValue(name, value)}
        />
      );
    } else {
      return (
        <IonIcon
          key={value}
          icon={starOutline}
          className="star"
          color="medium"
          onClick={() => this.setValue(name, value)}
        />
      );
    }
  };

  handleFeedback = () => {
    let formErrors;
    if (this.state.customerService) {
      formErrors = validateForm(this.formConfig, this.state);
    } else {
      formErrors = [];
    }

    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const {
        orderTypeFeedback,
        food,
        service,
        commentService,
        commentTech,
        selectedRestaurant,
        techSupport,
        customerService,
      } = this.state;
      const feedback = {
        food,
        service,
        commentService,
        commentTech,
        selectedRestaurant,
        techSupport,
        customerService,
        orderTypeFeedback,
      };
      this.props.dispatch(sendFeedback(feedback));
      // reset state
      this.setState({ ...this.resetState() });
    }
  };
  selectRestaurant = (selectedRestaurantId) => {
    this.setState({ selectedRestaurant: selectedRestaurantId });
    if (selectedRestaurantId !== null) {
      let formErrors = { ...this.state.formErrors };
      formErrors.selectedRestaurant = null;
      this.setState({ formErrors });
    }
  };

  setValue(field, value) {
    if (['customerService', 'techSupport'].indexOf(field) !== -1) {
      const decheckedKey = field === 'customerService' ? 'techSupport' : 'customerService';
      this.setState({ [field]: value, [decheckedKey]: false });
    } else {
      this.setState({ [field]: value });
    }
  }

  formatDataForSelect = (stores) => {
    const { __ } = this.props;
    let arrForSelect = [];
    stores.forEach((store) => {
      if (isDefined(store.is_published)) {
        if (store.is_published) {
          arrForSelect.push({ text: store.name, value: store.id });
        }
      }
    });
    return [{ text: __('Select Location'), value: null }, ...arrForSelect];
  };

  handleChange = (event) => {
    this.setValue(event.detail.value, true);
    this.setState({
      radioGroup: event.detail.value,
    });
  };
  backHandler = () => {
    if (this.props.location?.state?.fromBillPay) {
      forwardTo('/table-bill-pay');
    } else {
      goBack();
    }
  };
  componentDidMount() {
    const { restaurants } = this.props;
    if (!restaurants || restaurants.length === 0) {
      this.props.dispatch(getRestaurants());
    }
    const selectedRestaurant = this.props.location?.state?.selectedRestaurant;
    if (selectedRestaurant) {
      const selectedRestaurantId = restaurants.find(
        (restaurant) => restaurant.pos_location_id == selectedRestaurant,
      )?.id;
      this.selectRestaurant(selectedRestaurantId);
      this.setState({ customerService: false, techSupport: true, radioGroup: 'techSupport' });
      if (this.props.location?.state?.fromBillPay) {
        this.setState({ orderTypeFeedback: 'bill_pay' });
      }
    }
  }
  render() {
    const { __, isFeedbackModalOpen, restaurants } = this.props;
    const { commentService, commentTech, formErrors } = this.state;
    const techSupport = true;
    const stores = restaurants.sort((a, b) => {
      if (isDefined(a.name) && isDefined(b.name)) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
      return 0;
    });

    return (
      <Loading transparent>
        <Layout
          backHandler={this.backHandler}
          color="transparent"
          title={__('Leave Feedback')}
          headerWithTitle={false}
          showHamburger
        >
          <div className="absolute-content scrollable-y">
            <Title>{__('App Support')}</Title>

            {/* <div className="box-holder no-padding box-wrapper">
              <IonList lines="none">
                <IonRadioGroup
                  onIonChange={(event) => this.handleChange(event)}
                  value={this.state.radioGroup}
                >
                  <IonItem lines="none">
                    <div tabIndex="-1"></div>
                    <IonLabel>
                      <StrongText className="bold block">
                        {__('Customer Service')}
                      </StrongText>
                      <SmallText>{__('Any comments related to your visit')}</SmallText>
                    </IonLabel>
                    <IonRadio
                      slot="start"
                      color={'primary'}
                      className="margined-left margined-right"
                      value={'customerService'}
                    />
                  </IonItem>
                  <IonItem>
                    <div tabIndex="-1"></div>
                    <IonLabel>
                      <StrongText className="bold block">{__('Tech Support')}</StrongText>
                      <SmallText>{__('Any technical help using our app')}</SmallText>
                    </IonLabel>
                    <IonRadio
                      slot="start"
                      color={'primary'}
                      className="margined-left margined-right"
                      value={'techSupport'}
                    />
                  </IonItem>
                </IonRadioGroup>
              </IonList>
            </div> */}
            {techSupport ? (
              <div className="">
                <Spacer size={1} />

                <div className="feedback-box-wrapper input-field-container">
                  <NormalText className="light-color">{__('Comments')}</NormalText>
                  <div className="input-field-wrapper">
                    <IonTextarea
                      className="thiner-text"
                      value={commentTech}
                      onIonChange={(event) => this.setValue('commentTech', event.detail.value)}
                    ></IonTextarea>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <>
                  <Spacer size={1} />
                  <div className="feedback-box-wrapper input-field-container">
                    <NormalText>{__('Comments')}</NormalText>
                    <div className="commentService input-field-wrapper">
                      <IonTextarea
                        className="thiner-text"
                        value={commentService}
                        onIonChange={(event) => this.setValue('commentService', event.detail.value)}
                      ></IonTextarea>
                    </div>
                  </div>

                  <div className="input-field-container">
                    <NormalText>{__('Location')}</NormalText>
                    <IonItem lines="none" className="input-field-wrapper dropdown-field">
                      <SelectOption
                        display="center"
                        onSet={(e, a) => this.selectRestaurant(a.getVal())}
                        data={this.formatDataForSelect(stores)}
                        label="Location"
                        value={this.state.selectedRestaurant}
                        inputStyle="box"
                        placeholder={__('Select Restaurant')}
                        setText={__('OK')}
                        cancelText={__('Cancel')}
                      />
                    </IonItem>
                  </div>
                  {formErrors.selectedRestaurant ? (
                    <FieldError
                      className="field-error pad10"
                      value={__(formErrors.selectedRestaurant)}
                    />
                  ) : null}
                </>
                <Spacer size={1} />
                <div className="box-wrapper no-padding default-color">
                  <div className="stars-row">
                    <div className="flex-col-wrapper flex-align-center">
                      <div>
                        <StrongText className='bold'>{__('Rate Your Visit')}</StrongText>
                      </div>
                      <div className="righted">{this.createStarSet(5, 'food')}</div>
                    </div>
                    {this.state.formErrors.food ? (
                      <FieldError
                        className="field-error pad5l"
                        value={__(this.state.formErrors.food)}
                      />
                    ) : null}
                  </div>
                  <div className="stars-row">
                    <div className="flex-col-wrapper flex-align-center">
                      <div>
                        <StrongText className='bold'>{__('Rate Our Service')}</StrongText>
                      </div>
                      <div className="righted">{this.createStarSet(5, 'service')}</div>
                    </div>
                    {this.state.formErrors.service ? (
                      <FieldError
                        className="field-error pad5l"
                        value={__(this.state.formErrors.service)}
                      />
                    ) : null}
                  </div>
                </div>
              </>
            )}
            <Spacer />
            <IonButton expand="block" color="primary" onClick={this.handleFeedback}>
              {__('Submit')}
            </IonButton>
            {this.props.profile.is_guest && (
              <IonButton
                expand="block"
                color="primary"
                fill="outline"
                onClick={() => this.props.dispatch(logout())}
              >
                {__('Go back')}
              </IonButton>
            )}
          </div>
        </Layout>
        <IonAlert
          isOpen={isFeedbackModalOpen}
          onDidDismiss={() => this.props.dispatch(setCommonModal('isFeedbackModalOpen', false))}
          header={__('Thank you')}
          message={__('We really appreciate you taking the time to let us know how we did')}
          buttons={[
            {
              text: __('OK'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                this.props.dispatch(setCommonModal(('isFeedbackModalOpen', false)));
                this.setState({
                  food: 0,
                  service: 0,
                  customerService: true,
                  techSupport: false,
                  commentService: '',
                  commentTech: '',
                });
              },
            },
          ]}
        />
      </Loading>
    );
  }
}
const stateToProps = (store) => {
  const { profile } = store.profile;
  const { appVersion, isFeedbackModalOpen } = store.common;
  const { restaurants } = store.restaurants;
  return {
    appVersion,
    isFeedbackModalOpen,
    restaurants: restaurants || [],
    profile,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Feedback)));
